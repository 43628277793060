
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import Card from "primevue/card";

import FieldServiceHeaderButtons from '@/components/FieldService/FieldServiceHeaderButtons.vue';
import FieldServiceDataWrapper from "@/components/FieldService/FieldServiceDataWrapper.vue";
import Footer from "../Footer.vue";

export default defineComponent({
  name: "FieldServices",
  components: {
    Card,
    Footer,
    FieldServiceHeaderButtons,
    FieldServiceDataWrapper,
  },
  computed: {
    ...mapGetters({
      getCurrentCustomer: "customerInquiry/getCurrentCustomer",
    }),
  },
  data () {
    return {
      lastUpdated: Date.now(),
      isResultView: true as boolean,
    }
  },

  methods: {
    handleNewFieldService(editing: boolean, data = null as any) {
      if (editing && data) {
        this.$router.push(`/customers/${this.getCurrentCustomer.cust_id}/fieldservices/${data.order_no}`)
      } else {
        this.$router.push(`/customers/${this.getCurrentCustomer.cust_id}/fieldservices/new-service`)
      }
    },
    printExportData() {
      (this.$refs as any).fieldServiceTable.printTable();
    },
    clearFilters() {
      (this.$refs as any).fieldServiceTable.clearAllFiltersAndFetch();
    },
    isDataUpdated(event: number) {
      this.lastUpdated = event;
    },
  }
});

